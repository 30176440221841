import { CharityListItem } from '../charity-list-item/charity-list-item';
import { Charity } from '../../app-state/app-state';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

interface CharityListProps {
  charities: Charity[];
  charitiesLoading?: boolean;
  showAddCharity?: boolean;
  smallLogos?: boolean;
}

const NeedMoreCharities = () => (
  <>
    <h2 className="font-medium">Can't find the charity you're looking for?</h2>
    <span>
      Email us at{' '}
      <a
        className="inline link"
        href="mailto:fruitcakeapp@gmail.com?subject=Fruitcake%20App%20Charity%20Request"
      >
        fruitcakeapp@gmail.com
      </a>
    </span>
  </>
);

export const CharityList = ({
  charities,
  charitiesLoading,
  showAddCharity = true,
  smallLogos = false,
}: CharityListProps) => {
  if (charitiesLoading) {
    return <LoadingSpinner />;
  }

  return charities.length > 0 ? (
    <>
      <ul className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {charities.map((charity) => (
          <li
            key={charity.id}
            className="group grid grid-row-[auto_1fr_auto] h-full bg-white border border-gray-200 shadow-sm rounded-xl overflow-hidden dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]"
          >
            <CharityListItem charity={charity} smallLogo={smallLogos} />
          </li>
        ))}
        {showAddCharity && (
          <li className="grid content-center rounded-xl border border-grey-200 dark:border-gray-700 p-4 md:p-6">
            <NeedMoreCharities />
          </li>
        )}
      </ul>
    </>
  ) : (
    <>
      <h2 className="font-semibold mb-12">
        Sorry, no charities found that match your search.
      </h2>
      <NeedMoreCharities />
    </>
  );
};
