import { useEffect, ReactElement } from 'react';
import { useFruitcakeStore } from '../../app-state/app-state';
import { getApiUrl, ApiRoutes } from '../../utils/get-api-url';
import { constructGetRequestOptions } from '../../utils/construct-get-request-options';

interface ApiConnectorProps {
  children: ReactElement;
}

export const ApiConnector = ({ children }: ApiConnectorProps) => {
  const { email, currentDonationConfig, userHasClearedDonationConfig } =
    useFruitcakeStore((state) => state.appState);
  const {
    getUserEmail,
    setOnlineStatus,
    addToFetchQueue,
    setDonationsLoading,
    adjustDonationConfig,
    setUserData,
    fetchAndSetCsrfToken,
    subscribeToDonationsList,
    subscribeToNotifications,
    fetchCharities,
    fetchAppInfo,
  } = useFruitcakeStore((state) => state.actions);

  useEffect(() => {
    fetchAppInfo();
  }, [fetchAppInfo]);

  useEffect(() => {
    if (!email) {
      getUserEmail();
    } else {
      subscribeToDonationsList();
      subscribeToNotifications();
      fetchCharities();
    }
  }, [
    email,
    getUserEmail,
    subscribeToDonationsList,
    subscribeToNotifications,
    fetchCharities,
  ]);

  useEffect(() => {
    const handleOnline = () => setOnlineStatus(true);
    const handleOffline = () => setOnlineStatus(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [setOnlineStatus]);

  useEffect(() => {
    // Any time the user's email setting changes, fetch the updated CSRF token
    fetchAndSetCsrfToken();
  }, [email, fetchAndSetCsrfToken]);

  useEffect(() => {
    // Because a user follows a magic link for login, it's very likely we'll need
    // to fetch their user data. If we already have it, we don't need to fetch it again.
    if (!email) {
      addToFetchQueue(
        `${getApiUrl()}${ApiRoutes.USER_PROFILE}`,
        constructGetRequestOptions(),
        (data) => {
          if (data.email) {
            setUserData(data.email, data.payment_reference);
          }
        },
      );
    }
  }, [email, addToFetchQueue, setUserData]);

  // If logged in, check if the user has any donations and set to state if so, as donations
  // are needed for most routes.
  useEffect(() => {
    if (
      email &&
      currentDonationConfig.length <= 0 &&
      !userHasClearedDonationConfig
    ) {
      setDonationsLoading(true);

      try {
        addToFetchQueue(
          `${getApiUrl()}${ApiRoutes.USER_DONATIONS}`,
          constructGetRequestOptions(),
          (data) => {
            if (data.charities.length > 0) {
              adjustDonationConfig(data.charities, false);
            }
            setDonationsLoading(false);
          },
        );
      } catch (error) {
        console.log(error);
        setDonationsLoading(false);
      }
    }
  }, [
    email,
    currentDonationConfig,
    userHasClearedDonationConfig,
    addToFetchQueue,
    adjustDonationConfig,
    setDonationsLoading,
  ]);

  return children;
};
