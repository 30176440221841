import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  useFruitcakeStore,
  Donation,
  AppInfo,
} from '../../app-state/app-state';
import { routes } from '../../routes/routes';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { dp } from '../../utils/dp';

const PaymentDetails = ({
  paymentReference,
}: {
  paymentReference: string | undefined;
}) => (
  <div className="my-10">
    <div className="max-w-xl my-6 mx-auto overflow-hidden border border-gray-200 rounded-lg shadow-sm">
      <table className="min-w-full divide-y divide-gray-200">
        <tbody className="bg-white dark:bg-gray-700/20 divide-y divide-gray-200">
          <tr>
            <td colSpan={2} className="px-4 py-2 whitespace-nowrap text-sm">
              Fruitcake Industries Ltd
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium">
              Sort Code
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm">
              <code>04-00-03</code>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium">
              Account Number
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm">
              <code>15638381</code>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium">
              Payment reference
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm">
              {paymentReference}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <p>
      Please make sure to include your payment reference with each transaction,
      so we can match your donation to your settings.
    </p>
  </div>
);

const BackButton = ({ onClickAction }: { onClickAction: () => void }) => (
  <button
    onClick={onClickAction}
    className="absolute top-3 left-3 rounded-full bg-gray-100 p-2 text-gray-700 hover:bg-gray-200"
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1 L3 6 L9 11"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

const CurrentDonationConfigTable = ({
  currentDonationConfig,
}: {
  currentDonationConfig: Donation[];
}) => {
  return (
    <>
      <h3 className="mb-6 text-center">Your current charity settings:</h3>

      <div className="max-w-xl mb-6 mx-auto overflow-hidden border border-gray-200 rounded-lg shadow-sm">
        <table className="min-w-full divide-y divide-gray-200 bg-white dark:bg-gray-700/20">
          <thead className="text-left">
            <tr>
              <th className="px-4 py-2 text-sm font-medium">Charity</th>
              <th className="px-4 py-2 text-sm font-medium">Percentage</th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-700/20 divide-y divide-gray-200">
            {currentDonationConfig.map((donation) => (
              <tr key={donation.charity.id}>
                <td className="px-4 py-2 whitespace-nowrap text-sm">
                  <div className="flex items-center gap-2">
                    <img
                      height={24}
                      width={24}
                      src={donation.charity.logo_url}
                      alt={`${donation.charity.name} logo`}
                      className="inline-block bg-white"
                    />
                    <span>{donation.charity.name}</span>
                  </div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm">
                  {dp(donation.percentage, 1)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const FeeInfo = ({
  appInfo,
  exampleFruitcakeFee,
  exampleDonationAmount,
}: {
  appInfo: AppInfo | undefined;
  exampleFruitcakeFee: string | 0 | undefined;
  exampleDonationAmount: number;
}) => (
  <div>
    {appInfo?.fruitcake_percentage && exampleFruitcakeFee && (
      <p>
        <span className="font-semibold">Please note:</span> we take a{' '}
        {appInfo?.fruitcake_percentage}% fee on each donation. This helps cover
        our costs and keeps Fruitcake running smoothly. For example, if you
        donate £{exampleDonationAmount}, our fee is just
        {' ' + exampleFruitcakeFee}p.
      </p>
    )}
  </div>
);

export const DonateDialog = () => {
  const navigate = useNavigate();
  const {
    actions: { closeDonateDialog },
    appState: {
      isDonateDialogOpen,
      paymentReference,
      currentDonationConfig,
      appInfo,
    },
  } = useFruitcakeStore();

  const [view, setView] = useState('intro');
  const exampleDonationAmount = 10;
  const exampleFruitcakeFee =
    appInfo?.fruitcake_percentage &&
    BigNumber(exampleDonationAmount)
      .times(appInfo.fruitcake_percentage)
      .toFixed(0);

  const handleAdjustCharities = () => {
    closeDonateDialog();
    navigate(routes.DONATION_CONFIG);
  };

  // Dialog content based on the view state
  const dialogContent = () => {
    if (currentDonationConfig.length > 0) {
      switch (view) {
        case 'intro':
          return (
            <div data-testid="donate-dialog-intro">
              <CurrentDonationConfigTable
                currentDonationConfig={currentDonationConfig}
              />

              <div className="mt-4 text-center">
                <Button onClick={() => handleAdjustCharities()}>
                  Adjust my charities
                </Button>
              </div>

              <p className="mt-10">
                You can make donations either as a one-off or recurring, and
                make them as often as you wish. When you donate, we check your
                current donation settings and split your donation accordingly.
              </p>
              <div className="flex justify-center gap-4 my-10">
                <Button variant="CTA" onClick={() => setView('one-off')}>
                  Make a one-off donation
                </Button>
                <Button variant="CTA" onClick={() => setView('regular')}>
                  Set up a regular donation
                </Button>
              </div>
              <FeeInfo
                appInfo={appInfo}
                exampleFruitcakeFee={exampleFruitcakeFee}
                exampleDonationAmount={exampleDonationAmount}
              />
            </div>
          );
        case 'one-off':
          return (
            <div data-testid="donate-dialog-one-off">
              <BackButton onClickAction={() => setView('intro')} />
              <div data-testid="donate-dialog-one-off">
                <p>
                  To make a one-off donation, simply transfer your chosen amount
                  to Fruitcake's donation account. Make sure to include your
                  payment reference.
                </p>

                <PaymentDetails paymentReference={paymentReference} />

                <div className="mt-4 text-right">
                  <Button onClick={() => setView('donated')}>
                    I've donated, what happens now?
                  </Button>
                </div>
              </div>
            </div>
          );
        case 'regular':
          return (
            <div data-testid="donate-dialog-regular">
              <BackButton onClickAction={() => setView('intro')} />
              <div data-testid="donate-dialog-regular">
                <p>
                  To set up a regular donation, please create a standing order.
                  We don't take your money (direct debit), you control the
                  payments. You can usually do it yourself using online or
                  mobile banking – often in a section called regular payments –
                  or you can contact your bank. Make sure to include your
                  payment reference.
                </p>

                <PaymentDetails paymentReference={paymentReference} />

                <div className="mt-4 text-right">
                  <Button onClick={() => setView('donated')}>
                    I've donated, what happens now?
                  </Button>
                </div>
              </div>
            </div>
          );
        case 'donated':
          return (
            <div data-testid="donate-dialog-donated">
              <BackButton onClickAction={() => setView('intro')} />
              <div data-testid="donate-dialog-donated">
                <p>
                  We’ll let you know as soon as we’ve received your payment
                  (this can take up to an hour). We’ll then distribute your
                  payment according to your settings. You can change your
                  settings or donate again at any time.
                </p>
              </div>
            </div>
          );
        default:
          return null;
      }
    } else {
      return (
        <div className="mb-6">
          Ready to donate? Start by discovering and selecting your favourite
          charities here:
          <Link className="link" to={routes.DISCOVER}>
            Discover Charities
          </Link>
        </div>
      );
    }
  };

  return (
    <Dialog
      isOpen={isDonateDialogOpen}
      onClose={() => {
        setView('intro');
        closeDonateDialog();
      }}
      title={view === 'donated' ? 'Thank you!' : 'How to Donate'}
    >
      <div className="max-w-3xl">{dialogContent()}</div>
    </Dialog>
  );
};
