import { useEffect, useState } from 'react';
import { useFruitcakeStore, Notification } from '../../app-state/app-state';
import { Toast } from '../toast/toast'; // Import your custom Toast
import * as ToastPrimitive from '@radix-ui/react-toast';
import { formatDateString } from '../../utils/format-date-string';

export type NotificationType = 'error' | 'success' | 'info';

export const NotificationsManager = () => {
  const notifications = useFruitcakeStore(
    (state) => state.appState.notifications,
  );
  const markNotificationAsSeen = useFruitcakeStore(
    (state) => state.actions.markNotificationAsSeen,
  );

  const [activeNotifications, setActiveNotifications] = useState<
    Notification[]
  >([]);

  // Track active notifications and avoid multiple renders by comparing IDs
  useEffect(() => {
    const unseenNotifications = notifications.filter(
      (notification) => !notification.is_seen,
    );

    const newNotifications = unseenNotifications.filter(
      (notification) =>
        !activeNotifications.some((n) => n.id === notification.id),
    );

    if (newNotifications.length > 0) {
      setActiveNotifications((prev) => [...prev, ...newNotifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const dismissNotification = (id: string) => {
    markNotificationAsSeen(id, () => {
      setActiveNotifications((prev) =>
        prev.filter((notification) => notification.id !== id),
      );
    });
  };

  return (
    <>
      {activeNotifications.map((notification) => {
        let message: JSX.Element;
        let type: NotificationType = 'info';

        switch (notification.category) {
          case 'PAYMENT_RECEIVED':
            const paymentReceivedNotification = notification as Notification & {
              data: { transaction_id: string; amount_display: string };
              created_at: string;
            };
            message = (
              <>
                <div className="mb-2">
                  {formatDateString(paymentReceivedNotification.created_at)}
                </div>
                🎉 Thank you for your donation of{' '}
                {paymentReceivedNotification.data.amount_display}! We’re now
                allocating it to your chosen charities.
              </>
            );
            break;

          case 'NO_CHARITIES_CONFIGURED_FOR_PAYMENT':
            const noCharitiesNotification = notification as Notification & {
              data: { transaction_id: string; amount_display: string };
              created_at: string;
            };
            message = (
              <>
                <div className="mb-2">
                  {formatDateString(noCharitiesNotification.created_at)}
                </div>
                ⚠️ Oops! We received your donation of{' '}
                {noCharitiesNotification.data.amount_display}, but no charities
                are set up to receive it. Don’t worry, we’ll refund you shortly.
              </>
            );
            type = 'error';
            break;

          case 'PAYMENT_ALLOCATED':
            const paymentAllocatedNotification =
              notification as Notification & {
                data: {
                  transaction_id: string;
                  amount_display: string;
                  charity_name_percentage_pairs: Array<[string, string]>;
                };
                created_at: string;
              };
            const allocationDetails =
              paymentAllocatedNotification.data.charity_name_percentage_pairs
                .map((pair) => `${pair[0]}: ${pair[1]}`)
                .join(', ');
            message = (
              <>
                <div className="mb-2">
                  {formatDateString(paymentAllocatedNotification.created_at)}
                </div>
                💸 Your donation of{' '}
                {paymentAllocatedNotification.data.amount_display} has been
                allocated! Here’s where it’s heading: {allocationDetails}
              </>
            );
            type = 'success';
            break;

          default:
            message = <>Unknown notification category</>;
        }

        return (
          <Toast
            key={notification.id}
            message={message}
            onDismiss={() => dismissNotification(notification.id)}
            type={type}
          />
        );
      })}

      <ToastPrimitive.Viewport className="fixed top-12 right-5 p-6 flex flex-col gap-4" />
    </>
  );
};
