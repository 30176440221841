import { create } from 'zustand';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';

export interface Charity {
  id: string;
  name: string;
  description: string;
  logo_url: string;
  banner_url: string;
  strapline: string;
  categories: string[];
}

export interface Donation {
  percentage: number;
  charity: Charity;
}

export interface ProcessedDonation extends Donation {
  amount: string;
}

export interface Payment {
  transaction_id: string;
  received_at: string;
  amount: string;
  state: 'IN PROGRESS' | 'COMPLETED' | 'HALTED' | 'REFUNDED';
  donation_config: ProcessedDonation[];
}

export interface FetchRequestOptions {
  method: string;
  credentials: RequestCredentials;
  headers?: {
    'Content-Type': string;
    'X-CsrfToken': string;
  };
  body?: string | null;
}

export interface FetchRequest {
  id: string;
  url: string;
  options: FetchRequestOptions;
  attempts: number;
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => void;
}

export interface AppInfo {
  fruitcake_percentage: number;
}

interface NotificationPaymentReceivedData {
  transaction_id: string;
  amount_display: string;
}

interface NotificationNoCharitiesConfiguredForPaymentData {
  transaction_id: string;
  amount_display: string;
}

interface CharityNamePercentagePair {
  charity_name: string;
  percentage: number;
}

interface NotificationPaymentAllocatedData {
  transaction_id: string;
  amount_display: string;
  charity_name_percentage_pairs: CharityNamePercentagePair[];
}

export interface NotificationBase {
  id: string;
  is_seen: boolean;
  seen_at: string | null;
  email_sent: boolean;
  created_at: string;
}

export interface PaymentReceivedNotification extends NotificationBase {
  category: 'PAYMENT_RECEIVED';
  data: NotificationPaymentReceivedData;
}

export interface NoCharitiesConfiguredForPaymentNotification
  extends NotificationBase {
  category: 'NO_CHARITIES_CONFIGURED_FOR_PAYMENT';
  data: NotificationNoCharitiesConfiguredForPaymentData;
}

export interface PaymentAllocatedNotification extends NotificationBase {
  category: 'PAYMENT_ALLOCATED';
  data: NotificationPaymentAllocatedData;
}

// Union of all notification types
export type Notification =
  | PaymentReceivedNotification
  | NoCharitiesConfiguredForPaymentNotification
  | PaymentAllocatedNotification;

export interface AppState {
  appInfo?: AppInfo;
  email?: string;
  emailLoading: boolean;
  paymentReference?: string;
  userHasAttemptedLogin: boolean;
  currentDonationConfig: Donation[] | [];
  donationsLoading: boolean;
  userHasClearedDonationConfig: boolean;
  isOnline: boolean;
  fetchQueue: FetchRequest[];
  fetchQueueProcessing: boolean;
  errorToDisplay: string | null;
  csrfToken?: string;
  csrfTokenLoading: boolean;
  donationsList: Payment[] | [];
  isDonateDialogOpen: boolean;
  isLoginDialogOpen: boolean;
  charities: Charity[];
  charitiesLoading: boolean;
  exampleTotal?: string;
  showExampleTotal: boolean;
  isExampleMonetaryDialogOpen: boolean;
  emailLinkSent: boolean;
  theme: 'light' | 'dark';
  currentDonationToHighlight?: string;
  notifications: Notification[];
}

export interface AppStateActions {
  resetAppState: () => void;
  getUserEmail: () => void;
  setUserData: (email: string, paymentReference: string) => void;
  setDonationsLoading: (isLoading: boolean) => void;
  adjustDonationConfig: (donations: Donation[], sendToServer: boolean) => void;
  sendDonations: () => void;
  clearUserData: () => void;
  addToFetchQueue: (
    url: string,
    options: FetchRequestOptions,
    onSuccess?: (data: any) => void,
    onError?: (error: Error) => void,
  ) => void;
  processFetchQueue: () => Promise<void>;
  setOnlineStatus: (isOnline: boolean) => void;
  displayError: (error: string) => void;
  clearError: () => void;
  fetchAndSetCsrfToken: () => Promise<void>;
  setCsrfTokenLoading: (isLoading: boolean) => void;
  fetchDonationsList: () => void;
  fetchAppInfo: () => void;
  subscribeToDonationsList: () => void;
  openDonateDialog: () => void;
  closeDonateDialog: () => void;
  openLoginDialog: () => void;
  closeLoginDialog: () => void;
  fetchCharities: () => void;
  setExampleTotal: (total: string) => void;
  setShowExampleTotal: (show: boolean) => void;
  openExampleTotalDialog: () => void;
  closeExampleTotalDialog: () => void;
  setEmailLinkSent: (emailLinkSent: boolean) => void;
  setTheme: (theme?: 'light' | 'dark') => void;
  toggleTheme: () => void;
  setCurrentDonationToHighlight: (charityId: string) => void;
  clearCurrentDonationToHighlight: () => void;
  fetchNotifications: () => void;
  subscribeToNotifications: () => void;
  markNotificationAsSeen: (
    notificationId: string,
    onSuccess?: () => void,
  ) => void;
}

export interface AppStateStore {
  appState: AppState;
  actions: AppStateActions;
}

const isBrowser = typeof window !== 'undefined';

const getInitialTheme = (): 'light' | 'dark' => {
  if (!isBrowser) return 'light';

  const storedTheme = localStorage.getItem('theme');
  if (storedTheme === 'light' || storedTheme === 'dark') {
    return storedTheme;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

export const initialAppState: AppState = {
  email: undefined,
  emailLoading: true,
  appInfo: undefined,
  currentDonationConfig: [],
  userHasAttemptedLogin: false,
  donationsLoading: true,
  userHasClearedDonationConfig: false,
  isOnline: window.navigator.onLine,
  fetchQueue: [],
  fetchQueueProcessing: false,
  errorToDisplay: null,
  csrfToken: undefined,
  csrfTokenLoading: true,
  donationsList: [],
  isDonateDialogOpen: false,
  isLoginDialogOpen: false,
  exampleTotal: undefined,
  showExampleTotal: false,
  isExampleMonetaryDialogOpen: false,
  charities: [],
  charitiesLoading: false,
  emailLinkSent: false,
  theme: getInitialTheme(),
  notifications: [],
};

export const useFruitcakeStore = create<AppStateStore>((set, get) => ({
  appState: initialAppState,
  actions: {
    resetAppState: () => set(() => ({ appState: { ...initialAppState } })),
    getUserEmail: () => {
      const { addToFetchQueue, setUserData } = get().actions;

      set((state) => ({
        appState: { ...state.appState, emailLoading: true },
      }));

      addToFetchQueue(
        `${getApiUrl()}${ApiRoutes.USER_PROFILE}`,
        constructGetRequestOptions(),
        (data) => {
          if (data.email) {
            setUserData(data.email, data.payment_reference);
          }

          set((state) => ({
            appState: { ...state.appState, emailLoading: false },
          }));
        },
        (error) => {
          console.error('Error fetching user email:', error);

          set((state) => ({
            appState: { ...state.appState, emailLoading: false },
          }));
        },
      );
    },
    setUserData: (email, paymentReference) => {
      const { email: currentEmail } = get().appState;
      if (currentEmail !== email) {
        // Login state has changed, fetch new CSRF token
        get().actions.fetchAndSetCsrfToken();
      }
      set((state) => ({
        appState: { ...state.appState, email, paymentReference },
      }));
    },
    setDonationsLoading: (isLoading) => {
      set((state) => ({
        appState: { ...state.appState, donationsLoading: isLoading },
      }));
    },
    adjustDonationConfig: (donations, sendToServer = false) => {
      set((state) => ({
        appState: {
          ...state.appState,
          currentDonationConfig: donations,
          // This lets us differentiate between a user who has no
          // donations and a user who has cleared their donations
          userHasClearedDonationConfig: donations.length === 0,
        },
      }));

      if (!sendToServer) return;

      get().actions.sendDonations();
    },
    sendDonations: () => {
      const {
        appState: { currentDonationConfig, csrfToken },
        actions: { displayError, addToFetchQueue },
      } = get();

      const url = `${getApiUrl()}${ApiRoutes.USER_DONATIONS}`;

      const requestBody = {
        charities: currentDonationConfig,
      };

      const requestOptions = constructPostRequestOptions(
        csrfToken,
        requestBody,
      );

      const handleError = () => {
        displayError(
          'There was an issue saving your donations to the server. Please try again.',
        );
      };

      addToFetchQueue(url, requestOptions, () => null, handleError);
    },
    clearUserData: () => set(() => ({ appState: { ...initialAppState } })),
    addToFetchQueue: (
      url,
      options: FetchRequestOptions,
      onSuccess,
      onError,
    ) => {
      const id = `${url}`;

      set((state) => ({
        appState: {
          ...state.appState,
          fetchQueue: [
            ...state.appState.fetchQueue,
            { id, url, options, attempts: 0, onSuccess, onError },
          ],
        },
      }));

      get().actions.processFetchQueue();
    },
    processFetchQueue: async () => {
      const { isOnline, fetchQueue, fetchQueueProcessing } = get().appState;

      if (!isOnline) {
        console.log('App is offline, pausing fetch queue');
        return;
      }

      if (fetchQueue.length === 0) return;

      // We don't want the function to run concurrently. If it's already running,
      // we'll try again after 100ms
      // @TODO consider this further
      if (fetchQueueProcessing) {
        setTimeout(() => get().actions.processFetchQueue(), 100);
        return;
      }

      set((state) => ({
        appState: {
          ...state.appState,
          fetchQueueProcessing: true,
        },
      }));

      // Batching up requests for processing, allowing for other requests to the fetch
      // queue to be made while the current batch is being processed
      const inProgressQueue = [...fetchQueue];

      // Clear the fetch queue (any failed requests will be requeued)
      set((state) => ({
        appState: {
          ...state.appState,
          fetchQueue: [],
        },
      }));

      const uniqueRequests = Array.from(
        new Set(inProgressQueue.map((request) => request.id)),
      ).map((id) => inProgressQueue.find((request) => request.id === id));

      for (const request of uniqueRequests) {
        if (!request) continue;

        try {
          const response = await Promise.race([
            fetch(request.url, request.options),
            new Promise((_, reject) =>
              setTimeout(
                () =>
                  reject(new Error('Timeout: Could not connect to the server')),
                2000,
              ),
            ),
          ]);

          if (response instanceof Response) {
            if (!response.ok) {
              if (response.status === 401) {
                // @todo this catches cases where the user is logged out when we
                // attempt to get user data after following a magic link. However,
                // are there other cases where we might get a 401?
                console.log(`Unauthorized request to ${request.url}`);
              } else {
                throw new Error(`Fetch failed with status: ${response.status}`);
              }
            }
            const data = await response.json();

            if (request.onSuccess) {
              request.onSuccess(data);
            }
          } else {
            throw response;
          }
        } catch (error) {
          if (request.onError) {
            request.onError(error as Error);
          }

          if (request.attempts < 2) {
            // Requeue the request with an increased attempts count
            set((state) => ({
              appState: {
                ...state.appState,
                fetchQueue: [
                  ...state.appState.fetchQueue,
                  { ...request, attempts: request.attempts + 1 },
                ],
              },
            }));
            get().actions.processFetchQueue();
          } else {
            if (request.onError) {
              // In this case, we'll leave the error handling to the function
            } else {
              get().actions.displayError(
                'There are issues connecting to the server. Please reload the page and try again.',
              );
            }
          }
        }
      }

      set((state) => ({
        appState: {
          ...state.appState,
          fetchQueueProcessing: false,
        },
      }));
    },
    setOnlineStatus: (isOnline) => {
      set((state) => ({
        appState: {
          ...state.appState,
          isOnline,
        },
      }));

      if (isOnline) {
        get().actions.processFetchQueue();
      }
    },
    displayError: (error) => {
      set((state) => ({
        appState: {
          ...state.appState,
          errorToDisplay: error,
        },
      }));
    },
    clearError: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          errorToDisplay: null,
        },
      }));
    },
    setCsrfTokenLoading: (isLoading) => {
      set((state) => ({
        appState: { ...state.appState, csrfTokenLoading: isLoading },
      }));
    },
    fetchAndSetCsrfToken: async () => {
      const fetchCsrfToken = async (): Promise<string> => {
        const response = await fetch(
          `${getApiUrl()}${ApiRoutes.CSRF_TOKEN}`,
          constructGetRequestOptions(),
        );
        if (!response.ok) {
          throw new Error('Failed to fetch CSRF token');
        }
        const data = await response.json();
        return data.token as string;
      };

      const errorMessage =
        "We're missing some data from the server. Please reload the page to try again.";

      try {
        get().actions.setCsrfTokenLoading(true);

        const csrfToken = await fetchCsrfToken();
        const csrfTokenInState = get().appState.csrfToken;

        if (csrfToken && get().appState.errorToDisplay === errorMessage) {
          get().actions.clearError();
        }

        if (csrfToken === csrfTokenInState) return;
        set((state) => ({
          appState: {
            ...state.appState,
            csrfToken,
          },
        }));
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        get().actions.displayError(errorMessage);
      } finally {
        get().actions.setCsrfTokenLoading(false);
      }
    },
    fetchDonationsList: async () => {
      const {
        actions: { displayError, addToFetchQueue },
      } = get();

      const url = `${getApiUrl()}${ApiRoutes.PAYMENTS_LIST}`;

      const requestOptions = constructGetRequestOptions();

      const handleError = () => {
        displayError(
          'There was an issue fetching your payments from the server. Please try again.',
        );
      };

      const handleSuccess = (data: any) => {
        set((state) => ({
          appState: {
            ...state.appState,
            donationsList: data?.payments || [],
          },
        }));
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },
    fetchAppInfo: async () => {
      const {
        actions: { displayError, addToFetchQueue },
      } = get();

      const url = `${getApiUrl()}${ApiRoutes.APP_INFO}`;

      const requestOptions = constructGetRequestOptions();

      const handleError = () => {
        displayError(
          'There was an issue fetching some details from the server. Please reload the page and try again.',
        );
      };

      const handleSuccess = (data: any) => {
        set((state) => ({
          appState: {
            ...state.appState,
            appInfo: data,
          },
        }));
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },
    fetchCharities: async () => {
      const {
        actions: { displayError, addToFetchQueue },
        appState: { charities },
      } = get();

      set((state) => ({
        appState: {
          ...state.appState,
          charitiesLoading: true,
        },
      }));

      if (charities.length > 0) return; // Don't fetch if already present

      const url = `${getApiUrl()}${ApiRoutes.CHARITY_LIST}`;

      const requestOptions = constructGetRequestOptions();

      const handleError = () => {
        displayError(
          'There was an issue fetching charities from the server. Please refresh the page to try again.',
        );
        set((state) => ({
          appState: {
            ...state.appState,
            charitiesLoading: false,
          },
        }));
      };

      const handleSuccess = (data: any) => {
        set((state) => ({
          appState: {
            ...state.appState,
            charities: data?.results || [],
            charitiesLoading: false,
          },
        }));
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },
    subscribeToDonationsList: () => {
      const {
        appState: { isOnline },
        actions: { fetchDonationsList },
      } = get();

      fetchDonationsList();

      setInterval(
        () => {
          if (!isOnline) return;
          fetchDonationsList();
        },
        1000 * 60 * 5,
      ); // every 5 minutes
    },
    setExampleTotal: (total) => {
      set((state) => ({
        appState: {
          ...state.appState,
          exampleTotal: total,
        },
      }));
    },
    setShowExampleTotal: (show) => {
      set((state) => ({
        appState: {
          ...state.appState,
          showExampleTotal: show,
        },
      }));
    },
    openDonateDialog: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          isDonateDialogOpen: true,
        },
      }));
    },
    closeDonateDialog: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          isDonateDialogOpen: false,
        },
      }));
    },
    openLoginDialog: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          isLoginDialogOpen: true,
        },
      }));
    },
    closeLoginDialog: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          isLoginDialogOpen: false,
        },
      }));
    },
    openExampleTotalDialog: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          isExampleMonetaryDialogOpen: true,
        },
      }));
    },
    closeExampleTotalDialog: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          isExampleMonetaryDialogOpen: false,
        },
      }));
    },
    setEmailLinkSent: (emailLinkSent) => {
      set((state) => ({
        appState: {
          ...state.appState,
          emailLinkSent,
        },
      }));
    },
    setTheme: (theme) => {
      if (theme) {
        localStorage.setItem('theme', theme);
        if (theme === 'dark') {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
        set((state) => ({
          appState: {
            ...state.appState,
            theme,
          },
        }));
      }
    },
    toggleTheme: () => {
      const currentTheme = get().appState.theme;
      const newTheme = currentTheme === 'light' ? 'dark' : 'light';
      get().actions.setTheme(newTheme);
    },
    setCurrentDonationToHighlight: (charityId) => {
      set((state) => ({
        appState: {
          ...state.appState,
          currentDonationToHighlight: charityId,
        },
      }));
    },
    clearCurrentDonationToHighlight: () => {
      set((state) => ({
        appState: {
          ...state.appState,
          currentDonationToHighlight: undefined,
        },
      }));
    },
    fetchNotifications: async () => {
      const {
        actions: { displayError, addToFetchQueue },
      } = get();

      const url = `${getApiUrl()}${ApiRoutes.NOTIFICATIONS}`;

      const requestOptions = constructGetRequestOptions();

      const handleError = () => {
        displayError(
          'There was an issue fetching notifications from the server. Please try again.',
        );
      };

      const handleSuccess = (data: any) => {
        set((state) => ({
          appState: {
            ...state.appState,
            notifications: data?.results || [],
          },
        }));
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },
    subscribeToNotifications: () => {
      const {
        appState: { isOnline },
        actions: { fetchNotifications },
      } = get();

      fetchNotifications();

      setInterval(
        () => {
          if (!isOnline) return;
          fetchNotifications();
        },
        1000 * 60 * 5, // Poll every 5 minutes
      );
    },
    markNotificationAsSeen: (id: string, handleSuccess?: () => void) => {
      const {
        appState: { csrfToken },
        actions: { displayError, addToFetchQueue },
      } = get();

      const url = `${getApiUrl()}${ApiRoutes.NOTIFICATIONS_MARK_SEEN}`;

      const requestBody = { id };

      const requestOptions = constructPostRequestOptions(
        csrfToken,
        requestBody,
      );

      const handleError = () => {
        displayError(
          'There was an issue marking the notification as seen. Please try again.',
        );
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },
  },
}));
