import { useFruitcakeStore } from '../../app-state/app-state';
import { routes } from '../routes';
import { Link } from 'react-router-dom';
import { Sliders } from '../../components/sliders/sliders';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';

export const DonationConfig = () => {
  const { currentDonationConfig, donationsLoading } = useFruitcakeStore(
    (state) => state.appState,
  );
  const hasDonations = currentDonationConfig.length > 0;

  if (donationsLoading) {
    return <LoadingSpinner />;
  }

  return hasDonations ? (
    <>
      <h1>Your chosen charities</h1>
      <div className="mb-6">
        <Link className="link" to={routes.DISCOVER}>
          Discover more charities
        </Link>
      </div>
      <Sliders donations={currentDonationConfig} />
    </>
  ) : (
    <>
      <h1>Set up your donations</h1>
      <p>
        <span>To get started, </span>
        <Link className="link" to={routes.DISCOVER}>
          search for some charities
        </Link>
      </p>
    </>
  );
};
