import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { LoggedInRoutesConfig, LoggedOutRoutesConfig } from './routes-config';
import { useFruitcakeStore } from '../app-state/app-state';
import { RouteErrorBoundary } from './route-error-boundary';
import { LoadingSpinner } from '../components/loading-spinner/loading-spinner';

export const AppRouter = () => {
  const { email, emailLoading } = useFruitcakeStore((state) => state.appState);

  const isLoggedIn = !!email;
  const routeConfig = isLoggedIn ? LoggedInRoutesConfig : LoggedOutRoutesConfig;
  const routes = useRoutes(routeConfig);

  if (emailLoading) {
    return <LoadingSpinner />;
  }

  return (
    <RouteErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>{routes}</Suspense>
    </RouteErrorBoundary>
  );
};
