import { useState, useEffect } from 'react';
import { Charity, Donation } from '../../app-state/app-state';
import { CharityList } from '../charity-list/charity-list';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { Card } from '../card/card';

interface SimilarCharitiesProps {
  charities: Charity[];
  currentCharity: Charity;
  currentDonationConfig: Donation[];
  charitiesLoading: boolean;
  donationsLoading: boolean;
  recommendationCount?: number;
}

// Calculate the score based on category matches between the current charity and other charities
const calculateCharityScore = (
  charity: Charity,
  currentCharityCategories: string[],
) => {
  return charity.categories.reduce((score, category) => {
    return score + (currentCharityCategories.includes(category) ? 1 : 0);
  }, 0);
};

const getSimilarCharities = (
  allCharities: Charity[],
  currentCharity: Charity,
  currentDonationConfig: Donation[],
  recommendationCount: number = 4,
) => {
  const donatedCharityIds = new Set(
    currentDonationConfig.map((donation) => donation.charity.id),
  );

  // Filter and score based on category matches, exclude the current charity and those already donated to
  const similarCharities = allCharities
    .filter(
      (charity) =>
        charity.id !== currentCharity.id && !donatedCharityIds.has(charity.id),
    )
    .map((charity) => ({
      charity,
      score: calculateCharityScore(charity, currentCharity.categories),
    }))
    .filter(({ score }) => score > 0)
    .sort((a, b) => b.score - a.score)
    .slice(0, recommendationCount)
    .map(({ charity }) => charity);

  return similarCharities;
};

export const SimilarCharities = ({
  charities,
  currentCharity,
  currentDonationConfig,
  charitiesLoading,
  donationsLoading,
  recommendationCount = 4,
}: SimilarCharitiesProps) => {
  const [recommended, setRecommended] = useState<Charity[]>([]);

  useEffect(() => {
    if (!charitiesLoading && !donationsLoading) {
      const similarCharities = getSimilarCharities(
        charities,
        currentCharity,
        currentDonationConfig,
        recommendationCount,
      );
      setRecommended(similarCharities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    charitiesLoading,
    donationsLoading,
    charities,
    currentCharity,
    recommendationCount,
  ]);

  if (charitiesLoading || donationsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mt-36">
      <Card>
        <h2 className="mt-2 mb-6">
          Other charities you might be interested in
        </h2>
        <CharityList
          charities={recommended}
          showAddCharity={false}
          smallLogos={true}
        />
      </Card>
    </div>
  );
};
