import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { Charity, Donation } from '../../app-state/app-state';
import { CharityAdd } from '../charity-add/charity-add';

export const checkHasOtherDonations = (
  currentDonationConfig: Donation[],
  charityId: string,
) => {
  return Boolean(
    currentDonationConfig.filter(
      (donation) => donation.charity.id !== charityId,
    ).length,
  );
};

export const checkIsDonatingToCharity = (
  currentDonationConfig: Donation[],
  charityId: string,
) => {
  return currentDonationConfig.filter(
    (donation) => donation.charity.id === charityId,
  )[0];
};

const linkStyles =
  'w-full py-3 px-4 inline-flex flex-col justify-center items-center gap-0 font-medium shadow-sm align-middle transition-all text-sm sm:p-4 dark:border-gray-700';

interface CharityListItemProps {
  charity: Charity;
  smallLogo?: boolean;
}

export const CharityListItem = ({
  charity,
  smallLogo = false,
}: CharityListItemProps) => {
  return (
    <div className="grid grid-rows-[1fr_auto]">
      <Link to={`${routes.DISCOVER}/${charity.id}`} className="p-6">
        <div className="grid grid-cols-[auto_1fr] gap-6 rounded-t-xl">
          <div className="bg-white dark:opacity-90 dark:p-1 rounded-md grid place-content-center">
            <img
              className={`${
                smallLogo ? 'max-h-16 max-w-16' : 'col-span-2 max-h-32 max-w-32'
              } justify-self-center mb-1`}
              src={charity.logo_url}
              alt={charity.name}
            />
          </div>

          <h3 className="self-center text-xl font-semibold my-0">
            {charity.name}
          </h3>

          <div className="col-span-2 relative max-h-20 overflow-hidden">
            <p className="text-gray-500">
              {charity.description.substring(0, 200)}
            </p>
            <div className="absolute z-0 bottom-0 left-0 right-0 h-8 pointer-events-none bg-gradient-to-t from-white dark:from-slate-900 to-transparent"></div>
          </div>
        </div>
      </Link>

      <div className="mt-auto grid grid-cols-2 border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
        <Link to={`${routes.DISCOVER}/${charity.id}`} className={linkStyles}>
          Learn more
        </Link>

        <CharityAdd charity={charity} />
      </div>
    </div>
  );
};
